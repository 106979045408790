import * as Sentry from '@sentry/react';
import type { PropsWithChildren } from 'react';
import { lazy, useRef, Suspense } from 'react';
import { Outlet, useLocation, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { RouterErrorBoundaryFallback500Redirect } from '../components/RouterErrorBoundary/RouterErrorBoundary';

import { RedirectTo404 } from './guards/RedirectTo404';
import { RACRouterProvider } from './providers/RACRouterProvider';

const OffsettingLink = lazy(() => import('../pages/public/offsetting/link/OffsettingLink'));
const ErrorPage = lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage'));

// eslint-disable-next-line @typescript-eslint/ban-types -- eslint onboarding
function TransitionWrappedRoutes({ children }: PropsWithChildren<{}>) {
  const nodeRef = useRef(null);
  const location = useLocation();

  return (
    <SwitchTransition>
      <CSSTransition appear={true} timeout={0} nodeRef={nodeRef} key={location.pathname.split('/')[1]}>
        <div ref={nodeRef}>
          <Suspense fallback={null}>{children}</Suspense>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

const sentryCreateBrowserRouter =
  process.env.NODE_ENV === 'production' ? Sentry.wrapCreateBrowserRouter(createBrowserRouter) : createBrowserRouter;

const router = sentryCreateBrowserRouter(
  [
    {
      element: (
        <RACRouterProvider>
          <TransitionWrappedRoutes>
            <Outlet />
          </TransitionWrappedRoutes>
        </RACRouterProvider>
      ),
      ErrorBoundary: RouterErrorBoundaryFallback500Redirect,
      children: [
        {
          path: '/:linkId/*',
          element: <OffsettingLink />,
        },
        {
          path: '/404',
          element: <ErrorPage />,
        },
        {
          path: '/500',
          element: <ErrorPage />,
        },
        {
          path: '*',
          element: <RedirectTo404 />,
        },
      ],
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

export function RouterImpact() {
  return (
    <RouterProvider
      router={router}
      fallbackElement={null}
      future={{
        v7_startTransition: true,
      }}
    />
  );
}
